<template>
    <v-card flat class="pa-3">
  <PrimeDataTable
    v-if="viewType!='show-members'"
    :title="'WorkSpace'"
    :showFilter="true"
    :searchOption="true"
    :selectAll="true"
    :showDownload="true"
    :showUpload="true"
    :showAdd="true"
    :columnData="columns"
    :data="data"
    @addDiolog="addDiolog()"
    @rowAction="rowActions($event)"
    activity="workspace"
  />
</v-card>
  <MembersList  v-if="viewType=='show-members'" @closeDialog="close($event)" :wsData="selectedWorkSpaceData" :workSpaceId="selectedWorkSpaceId"/>

  <v-dialog v-if="dialog" persistent v-model="dialog" width="800px">
   
      <CreateWorkSpace
      @closeDialog="close()"
      @reload="getWorkSpace()"
      :id="editId"
      v-if="dialog"
      :viewType="viewType"
    />
  </v-dialog>
</template>
  
  <script>
import MembersList from "./MembersList.vue";
import CreateWorkSpace from "./CreateWorkSpace.vue";
import PrimeDataTable from "@/components/PrimeDataTable";
import WorkSpaceService from "@/api/service/WorkSpace/WorkSpaceService";
// import commonService from "@/common.service";
export default {
  components: {
    CreateWorkSpace,
    PrimeDataTable,
    MembersList
  },
  data() {
    return {
      selectedWorkSpaceData:null,
      selectedWorkSpaceId:null,
      data: [],
      columns: [],
      date: new Date(),
      editId: null,
      dialog: false,
      viewType: false,
    };
  },
  created() {
    this.loadColumns();
    this.download = true;
    this.search = true;
    this.checkbox = true;
    this.getWorkSpace();
  },
  methods: {
    loadColumns() {
      this.columns = [
        {
          field: "code",
          header: "Code",
          style: { width: "100px", color: "" },
          toolTip: "code",
          sortfield: true,
        },
        {
          field: "name",
          header: "Name",
          style: { width: "100px", color: "" },
          toolTip: "name",
          sortfield: true,
        },
        
        {
          field: "modified",
          header: "Modified",
          style: { width: "100px", color: "" },
          toolTip: "modified",
          sortfield: true,
        },
        {
          field: "active",
          header: "Status",
          style: { width: "100px", color: "" },
          toolTip: "active",
          sortfield: true,
        },

         {
          field: "action",
          header: "Action",
          style: { width: "100px", color: "" },
          toolTip: "Action",
          sortfield: true,
          rowActions: [
            { action: "view" },
            { action: "edit" },
            // { action: "delete" },
            { action: "pi-users","event":"show-members",route:"/addMembers" },
          ],
        },
      ];
    },

    close($event) {
      if($event =='closeMember')
      {
        this.viewType=''
      }
      this.dialog = false;
      this.loadColumns();
    },
    rowActions($event) {
      if ($event.type == "view") {
        this.dialog = true;
        this.editId = $event.data.id;
        this.viewType = $event.type;
        this.workspaceId = $event.data.id
      }
      if ($event.type == "edit") {
        this.dialog = true;
        this.editId = $event.data.id;
        this.viewType = $event.type;
        this.workspaceId = $event.data.id
      }

      if ($event.type == "select") {
        this.dialog = true;
        this.editId = $event.data.id;
        this.viewType = "view"
        this.workspaceId = $event.data.id
      }
      if($event.type=='show-members') {
        this.dialog= false
        this.selectedWorkSpaceData = $event.data
        this.selectedWorkSpaceId = $event.data.id
        this.viewType='show-members'
        
      }
    },
    async getWorkSpace() {
      let res = await WorkSpaceService.findAll();
      this.data = res;
    },
    addDiolog() {
      this.dialog = true;
      this.editId = null;
      this.viewType = false
    },
  },
};
</script>
  
  
  