<template>
    <PrimeDataTable
      :title="'Sub Category'"
      :showFilter="true"
      :searchOption="true"
      :selectAll="true"
      :showDownload="true"
      :showUpload="false"
      :showAdd="true"
      :columnData="columns"
      :data="data"
      @addFilter="addFilter()"
      @addDiolog="addDiolog()"
      @rowAction="rowActions($event)"
      activity="subcategory"

    />
    <FilterDrawer ref="FilterDrawer"/>
    <v-dialog persistent v-model="dialog" width="700px">
    <AddSubCategory :viewType="viewType" @closeDialog="setFalse()" @reload="getSubCategory()" :id="subCategoryId" ref="AddSubCategory"/>
</v-dialog>

  </template>
  
  <script>
  import AddSubCategory from "./AddSubCategory.vue";
  import FilterDrawer from "@/components/FilterDrawer.vue";
  import PrimeDataTable from "@/components/PrimeDataTable";
  import SubCategoryService from "@/api/service/SubCategory/SubCategoryService";
//   import categoryServis from "@/api/service/Category/CategoryService.js";
  import commonService from "@/common.service";
  export default {
    components: {
      AddSubCategory,
      PrimeDataTable,
      FilterDrawer
    },
    data() {
      return {
        data: [],
        columns: [],
        date: new Date(),
        categoryId: null,
        dialog:false,
        viewType: false,
        subCategoryId:''
      };
    },
    created() {
      this.loadColumns();
      this.download = true;
      this.search = true;
      this.checkbox = true;
      this.getSubCategory();
    },
    methods: {
      loadColumns() {
        this.columns = [
          {
            field: "code",
            header: "Code",
            style: { width: "100px", color: "" },
            toolTip: "Code",
            sortfield: true,
          },
          {
            field: "name",
            header: "Name",
            style: { width: "100px", color: "" },
            toolTip: "Name",
            sortfield: true,
          },
          {
            field: "categories.name",
            header: "Category",
            style: { width: "100px", color: "" },
            toolTip: "Category",
            sortfield: true,
          },
          {
            field: "qa_required",
            header: "QA Required",
            style: { width: "100px", color: "" },
            toolTip: "Qa Required",
            sortfield: true,
          },
          {
            field: "modified",
            header: "Modified",
            style: { width: "100px", color: "" },
            toolTip: "Modified",
            sortfield: true,
          },
          {
            field: "status",
            header: "Status",
            style: { width: "100px", color: "" },
            toolTip: "Status",
            sortfield: true,
          },
          {
            field: "action",
            header: "Action",
            style: { width: "100px", color: "" },
            toolTip: "Action",
            sortfield: true,
            rowActions: [{action:"view"},{action:"edit"},],
          },
        ];
      },
      setFalse(){
        this.dialog=false;
      },
  
      rowActions($event) {
        if ($event.type == "view") {
          this.dialog = true;
          this.viewType= $event.type;
          this.subCategoryId = $event.data.id;
        }
        if ($event.type == "edit") {
          this.dialog = true;
          this.viewType= $event.type;
          this.subCategoryId = $event.data.id;
        }
  
        if ($event.type == "select") {
          this.dialog = true;
          this.viewType= 'view';
          this.subCategoryId = $event.data.id;
  
          console.log("select");
        }
      },
  
      async getSubCategory() {
        try {
          let res = await SubCategoryService.findAll();
          this.data = res;
          this.data.forEach(element => {
            element.qa_required = element.qa_required && element.qa_required == 1 ?'Yes':'No'
          });
        } catch (exception) {
          this.data = [];
          console.log(exception, 'error');
        }
      },
  
      addDiolog() {
        this.dialog = true;
        this.viewType = false;
        this.subCategoryId=null;
        },
      addFilter() {
        this.$refs.FilterDrawer.dialog = true;
        this.$refs.FilterDrawer.size = commonService.largeDialog();
      },
    },
  };
  </script>
  
  
  