<template>
    <v-card width="100%" style="border-radius: 12px; overflow: hidden;min-height:50vh;">
        <v-card-item>
            <v-card-title class="font-weight-black">
                Add Members to Workspace{{ wsData && wsData.name ?`(${wsData.name})`:''}}

            </v-card-title>
            <v-btn @click="close()" elevation="0" density="comfortable" icon="mdi-close" class="x-closeIcon"></v-btn>
        </v-card-item>
        <v-card-text>
            <PrimeDataTable :title="workspace && workspace.name" :fileName="'MemberList'" :showFilter="true" :searchOption="true" :selectAll="true"
                :showDownload="true" :showUpload="true" :showAdd="true" :columnData="columns" :data="members"
                @addDiolog="addDialog()" @rowAction="rowActions($event)" activity="member" />
        </v-card-text>
        <v-dialog v-if="dialog" persistent v-model="dialog" max-width="600px">
            <Members :workSpaceId="workSpaceId" :viewType="viewType" :id="editId" @member-added="getWorkSpaceTeam"
                @close-dialog="dialog = false" />
        </v-dialog>
    </v-card>
</template>
<script>
//import WorkSpaceService from '@/api/service/WorkSpace/WorkSpaceService';
import Members from './Members.vue'
import WorkSpaceTeamService from '@/api/service/WorkSpaceTeam/WorkSpaceTeamService';
import UserService from '@/api/service/UserService';
import PrimeDataTable from "@/components/PrimeDataTable.vue";
import RoleService from "@/api/service/Role/RoleService";
import WorkSpaceService from "@/api/service/WorkSpace/WorkSpaceService";
export default {
    props: [
        "workSpaceId",
        "wsData"
    ],
    components: {
        PrimeDataTable,
        Members
    },
    data() {
        return {
            data: [],
            columns: [],
            date: new Date(),
            editId: null,
            dialog: false,
            viewType: false,
            form: {
                user_id: null,
                role_id: null,
                active: null
            },
            members: [],
            workspace: {},
            teams: [],
            userData: [],
            roleData: [],
            addRow: 0,
            showbtn: false

        }
    },
    created() {
        this.columns = [
            {
                field: "users.name",
                header: "Member",
                style: { width: "100px", color: "" },
                toolTip: "name",
                sortfield: true,
            },
            {
                field: "roles.name",
                header: "Role",
                style: { width: "100px", color: "" },
                toolTip: "name",
                sortfield: true,
            },
            {
                field: "start_date",
                header: "Start Date",
                style: { width: "100px", color: "" },
                toolTip: "Start Date",
                type: "date",
                sortfield: true,
            },
            {
                field: "end_date",
                header: "End date",
                style: { width: "100px", color: "" },
                toolTip: "End date",
                type: "date",   
                sortfield: true,
            },
            {
                field: "modified",
                header: "Modified",
                style: { width: "100px", color: "" },
                toolTip: "modified",
                sortfield: true,
            },

            {
          field: "status",
          header: "Status",
          style: { width: "100px", color: "" },
          toolTip: "Status",
          sortfield: true,
        },


            {
                field: "action",
                header: "Action",
                style: { width: "100px", color: "" },
                toolTip: "Action",
                sortfield: true,
                rowActions: [
                    { action: "view" },
                    { action: "edit" },
                    // { action: "delete" }
                ],
            },
        ];
        this.getWorkSpace()
        this.getWorkSpaceTeam();
        this.getUser();
        this.getRole();

    },
    mounted() {

    },
    methods: {
        newMember($id) {
            WorkSpaceTeamService.findOne({ "id": $id })
                .then($team => {
                    this.members.push($team[0])
                    this.dialog = false
                })
        },
        addDialog() {
            this.dialog = true;
            this.editId = null;
            this.viewType = false;
        },
        rowActions($event) {
            if ($event.type == "view") {
                this.dialog = true;
                this.editId = $event.data.id;
                this.viewType = $event.type;
            }
            if ($event.type == "edit") {
                this.dialog = true;
                this.editId = {id:$event.data.id,userId:$event.data.user_id}
                this.viewType = $event.type;
            }

            if ($event.type == "select") {
                this.dialog = true;
                this.editId = $event.data.id;
                this.viewType = "view";
            }
        },

        getWorkSpace() {
            WorkSpaceService.findOne({ "id": this.workSpaceId })
                .then($options => {
                    this.workspace = $options[0]
                })
        },
        getWorkSpaceTeam() {
            WorkSpaceTeamService.findAll({ "work_space_id": this.workSpaceId })
                .then($options => {
                    this.members = $options
                    this.dialog = false
                })
        },
        async getUser() {
            let $response = await UserService.findAll()
            this.userData = $response
        },
        async getRole() {
            let $response = await RoleService.findAll()
            this.roleData = $response

        },
        addMember() {
            this.members.push({
                user_id: null,
                role_id: null,
                active: null
            })
        },
        close() {
            this.$emit('closeDialog','closeMember')
        },
    }
}
</script>