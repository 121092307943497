<template>
  <v-layout>
    <v-app-bar height="71" :elevation="0" color="#1258ab">
      <!-- <v-icon class="pl-6" color="#FFFFFF" size="25" @click="openSideBar()">mdi-view-headline</v-icon> -->
      &nbsp;
      <span style="color: #ffffff" class="pl-6 text-h6"> <b> Synergy </b></span>

      <ProjectSelection v-if="false" />

      <AccountSelection v-if="false" />
      
      <template v-slot:append>
        <div class="iconDiv">
          <Timer :timerInProgress="getTimerInProgress" :title="timerTitle" :showHomeCard="false" v-on:closeDialog1="closeTimerDialog($event)"
            @openTimer="openTimerDialog()"
            v-on:openDialog="openDialog"
            :showInBetweenTime="showIBT"
            v-if="((($route.path != '/') || meetingTimer) && !timerDialog && $route.path!='/issueView2') || showIBT" />
          <!-- <v-btn v-if="($route.path != '/issueView2' && getTimerInProgress) || (meetingTimer)" variant="text"
            @click="updateTimeSheet(true)" class="pa-2  btn-color">
            <v-img src="../assets/pause.svg" style="width:16px;height:16px;color:#ffffff" />
          </v-btn>
           -->
           <v-btn variant="text" @click="$router.push('/')"><v-icon color="#FFFFFF"
              size="25">mdi-home-outline</v-icon>
            <v-tooltip activator="parent" location="bottom">Home</v-tooltip>
          </v-btn>
          <!-- <v-btn variant="text" @click="showImpersonateDialog=!showImpersonateDialog"><v-icon color="#FFFFFF" size="25">
          mdi mdi-account-question
        </v-icon>
          <v-tooltip activator="parent" location="bottom">Impersonate User</v-tooltip>
        </v-btn> -->
          <!-- <v-btn variant="text" @click="$router.push('/myWorkSpace')"><span style="color: #ffffff; ">My Space</span> -->
            <v-menu location="bottom" 
            v-model="menu"
           :close-on-content-click="false"
           >
              <template v-slot:activator="{ props }">
              <v-btn variant="text"  v-bind="props">
              <span style="color: #ffffff; ">My Space</span>
          </v-btn></template> 
          <workSpaceCard @closeMenu="closedMenu()" /></v-menu>

          <v-btn id="meeting-menu-activator" variant="text">
            <img class="img" src="https://new-synergy-snaps.s3.ap-south-1.amazonaws.com/public/icons/command.svg">
            <v-tooltip activator="parent" location="bottom">Meeting</v-tooltip>
          </v-btn>
          <v-btn @click="startTimer('break')" variant="text">
            <img class="img" src="https://new-synergy-snaps.s3.ap-south-1.amazonaws.com/public/icons/cupcake.svg">
            <v-tooltip activator="parent" location="bottom">Break</v-tooltip>
          </v-btn>


          <v-btn  @click="openTeamStatus()" variant="text" style="font-size: 20px;padding-top: 5px;">
            <v-icon>mdi-account-group-outline</v-icon>
            <v-tooltip activator="parent" location="bottom">Team Status</v-tooltip>
          </v-btn>

        </div>
        <v-spacer style="width:30px" />

        <WorkspaceSelection displayType='avatar' />
        <v-spacer style="width:30px" />

        <SearchIssues />

        <v-btn variant="text" id="plus-menu-activators">
          <v-icon color="#FFFFFF" size="25">mdi-plus</v-icon>
          <v-tooltip activator="parent" location="bottom">Add</v-tooltip>
        </v-btn>
       
        <v-btn variant="text" v-if="false" @click="openNotification"><v-icon color="#FFFFFF" size="25">mdi-bell-outline</v-icon>
          <v-tooltip activator="parent" location="bottom">Notification</v-tooltip>
        </v-btn>
        <v-btn variant="text" id="menu-activator"><v-icon color="#FFFFFF" size="25">mdi-account-circle-outline</v-icon>
          <v-tooltip activator="parent" location="bottom">Profile</v-tooltip>
        </v-btn>

        <!-- <v-btn variant="text" @click="$router.push('/settings')"><v-icon color="#FFFFFF" size="25">mdi-cog-outline</v-icon></v-btn> -->
        <MenuOptions :options="addMenuOptions" activator="#plus-menu-activators" />
        <MenuOptions @menu-selected="logOut($event)" :options="userProfileOptions" activator="#menu-activator" />
        <MenuOptions @menu-selected="startMeeting($event)" :options="meetingTasks" activator="#meeting-menu-activator" />
      </template>
    </v-app-bar>
  </v-layout>
  
  <v-dialog persistent :width="300" v-model="timerDialog">
    <!-- <SetTimer :title="timerTitle" v-on:closeTimerDialog="minimizeTimer()" v-on:closeDialog1="closeTimer()"></SetTimer> -->
    <Timer :timerInProgress="getTimerInProgress" :showHomeCard="false" :title="timerTitle" :timerName="timerName"
      v-on:closeTimerDialog="minimizeTimer()" v-on:closeDialog1="closeTimer($event)" :taskType="taskType" :showPlay="true"
      :hideCard="true" />
  </v-dialog>
    
  
  <!-- <v-dialog persistent v-model="dialog" class="notification" width="500px">
    <Notification @close-dialog="closeDialog"></Notification> -->
  <!-- </v-dialog> -->
  <NavigationDrawer ref="NavigationDrawer" />
  <TeamStatus ref="TeamStatus" />
  <!-- <Notification ref="Notification" /> -->
  <ImpersonateUser :showDialog="showImpersonateDialog" @close-dialog="closeImpersonateDialog()"/>
  
  <v-dialog persistent :width="600" v-model="maDialog" >
    <MeetingAccountSelection :data="meetingData" @closeDialog="maDialog = false" @account-selection="againStartMeeting" />
  </v-dialog>
</template>

<script>
import TeamStatus from './TeamStatus.vue'
import ImpersonateUser from '@/modules/users/ImpersonateUser.vue'
import workSpaceCard from '@/modules/dashboard/user/workSpaceCard.vue';
import Timer from './Timer.vue'
import MenuOptions from './MenuOptions.vue'
import AccountSelection from './AccountSelection.vue'
import TimeSheetService from '@/api/service/TimeSheet/TimeSheetService.js';
import lodash from "lodash";
import TimerService from '@/api/service/TimeSheet/TimerService'
import moment from 'moment'
import UserService from '@/api/service/UserService';
// import TaskService from "@/api/service/Task/TaskService"
import IssueService from "@/api/service/Issue/IssueService";
import WorkspaceSelection from "./WorkspaceSelection.vue";
import ProjectSelection from './ProjectSelection.vue'
// import Notification from "@/modules/Notification/Notification.vue";
import NavigationDrawer from "./NavigationDrawer.vue";
import AccountService from "@/api/service/Account/AccountService";
import ProjectService from "@/api/service/Project/ProjectService";
import WorkSpaceService from "@/api/service/WorkSpace/WorkSpaceService";
import SearchIssues from "@/components/ui/SearchIssues.vue";
import TaskService from '@/api/service/Task/TaskService'
import MeetingAccountSelection from './MeetingAccountSelection.vue';
// import SetTimer from '@/modules/settings/Others/SetTimer.vue';
export default {
  components: {
    ImpersonateUser,
    TeamStatus,
    workSpaceCard,
    Timer,
    NavigationDrawer,
    // Notification,
    WorkspaceSelection,
    AccountSelection,
    MenuOptions,
    ProjectSelection,
    SearchIssues,
    MeetingAccountSelection
    // SetTimer,
  },

  data() {
    return {
      showImpersonateDialog:false,
      meetingTasks:[],
      // count:null,
      activated: false,
      maDialog:false,
      timerRunning: false,
      dialog: false,
      timerDialog: false,
      meetingTimer: false,
      timerShow: false,
      fav: true,
      menu: false,
      menuTeam: false,
      timerTitle: '',
      timerName:'',
      message: false,
      hideTimer: false,
      hints: true,
      show: false,
      avatarName: null,
      maxAvatarLabelCount: 4,
      // colors: ["#2196F3", "#4CAF50", "#FF9800", "#9C27B0", "#F44336"],
      userData: null,
      meetingData:null,
      workSpaceData: [],
      projectData: [],
      accountData: [],
      addedMenu: [],
      host: window.location.host,
      workTimingId:'',
      showIBT:false,
      userProfileOptions: [
        { title: "User Profiles", "action": "/user" }, 
        { title: "Logout"}
      ],
      addMenuOptions: [
        // {
        //   title: "Add Project",
        //   action: "/projectList",
        //   query: { "from": "topMenu" }
        // },
        // {
        //   title: "Add Sub Task",
        //   action: "/setting/project/subtask",
        //   query: { "from": "topMenu" }
        // },
        {
          title: "Add Quick Task",
          action: "/addQuickIssue",
          query: { "from": "topMenu" }

        },
      ]
    };
  },
  watch: {
    refreshProject() {
      if (this.$store.getters.getWorkSpaceId) {
        localStorage.setItem('workSpaceId',this.$store.getters.getWorkSpaceId)
        localStorage.removeItem("_PROJECT_ID_");
        localStorage.removeItem("_ACCOUNT_ID_");
        this.getProjectData();
        this.getAccountData();
      }
    },
    getInBetweenTime(){
      this.checkInBetweenTime()
    }
  },

  computed: {
    checkRoute(){
      return window.location.pathname
    },
    getTimerInProgress() {
      return this.$store.getters.getInProgress
    },
    refreshProject() {
      return this.$store.getters.getWorkSpaceId;
    },
    getInBetweenTime() {
      return this.$store.getters.getInBetweenTime
    },
  },
  created() {
    let timerData = JSON.parse(localStorage.getItem("timer"))    
    // this.showIBT = this.getInBetweenTime == true ?true:false
    if (timerData && timerData.type && timerData.type != process.env.VUE_APP_IBT_CODE) {
      this.showIBT = false
      this.timerDialog = timerData.type != 'issue'
      this.meetingTimer = false
      this.timerRunning = true
      if (this.$route.path.includes('issueView2')) {
        this.timerRunning = false
      }
      console.log(timerData,"hhhhhhhhhhhhhhhhhheeee")
      this.timerTitle = timerData.type ? timerData.type : 'meeting'
      // this.timerTitle = timerData.type ? timerData.type:timerData.type=='meeting'?timerData.meetingName: 'meeting'
      this.timerName = timerData.type && timerData.type.toLowerCase()=='meeting' ? timerData.meetingName:timerData.type
    }
    else if(timerData && timerData.type && timerData.type == process.env.VUE_APP_IBT_CODE){
      this.showIBT = true
      this.timerRunning = false
      this.meetingTimer = false
      this.timerDialog = false
    }
    else {
      this.timerRunning = false
      this.meetingTimer = false
      this.timerDialog = false
    }
    this.extraAvatar();
    this.getWorkSpaceData();
    this.userData = JSON.parse(localStorage.getItem("user_details"));
    this.getAvatarMenu();
    // this.getMeetingTasks()
    // let workSpaceId = localStorage.getItem('workSpaceId')
    // this.getProjectData();
    // this.getAccountData();
    // let userLogin =localStorage.getItem("user_login")
    /*if(!userLogin){
      this.userEntry();
    }*/
    this.getMeetingTasks()
  },

  methods: {
    checkInBetweenTime(){
      let timerData = localStorage.getItem("timer")
      timerData = timerData ? JSON.parse(timerData) :null 
      this.showIBT = timerData && timerData.type && timerData.type == process.env.VUE_APP_IBT_CODE ?true:false
    },
    openDialog(){
      this.timerDialog=true
    },
    startMeeting($selected) {
     console.log($selected,"meeting dataaaa")
     this.meetingData =$selected
     let checkCode = ['ME', 'CA', 'TR', 'MBS']
     if($selected && $selected.code && checkCode.includes($selected.code)){
        this.maDialog = true
     }
     else{
      this.maDialog = false
      //alert(JSON.stringify($selected,null,4))
      this.startTimer('meeting',$selected)
     }
    },
    againStartMeeting($selected){
      this.maDialog = false
      this.meetingData =$selected
      this.startTimer('meeting',$selected)
    },
   async getMeetingTasks() {
      let $tasks = await TaskService.findAll({"task_type":"meeting",active:1})
      this.meetingTasks = $tasks;
    },
    closeImpersonateDialog() {
    
      this.showImpersonateDialog=false
    },
    atClick() {
      this.$router.push({ path: "/ProjectView" });
    },
    menuSelected($event){
      console.log($event)
    },
    logOut($event) {
      let $timerData = JSON.parse(localStorage.getItem('timer'))
      if($event.title == 'Logout'){
        if ($timerData && $timerData.type && $timerData.type != process.env.VUE_APP_IBT_CODE) {
          this.$store.dispatch('setSnackbar',{ color:'error',text:`Stop the running ${$timerData.type} before LOGOUT` })
        }else{
          this.$router.push('/logout')
        }
      }
    },
    closeTimerDialog($event) {
      if (!this.timerDialog) {
        this.closeTimer($event)              
      }
    },
    async updateTimeSheet() {
      let issueData = JSON.parse(localStorage.getItem("timer"))
      if (issueData && issueData.type == 'issue') {
        /*let res = await TimeSheetService.findAll({ issue_id: issueData.issueId });
        res = lodash.orderBy(res, ['id'], ['desc']);
        let timesheetData = res[0]
        let data = {}
        data.id = timesheetData.id
        data.end_date = moment(new Date()).utcOffset("+05:30").format('YYYY/MM/DD HH:mm:ss')
        let end_date = moment(new Date()).tz('utc')
        data.stage = 'completed'
        let start_date = moment(timesheetData.start_date, true).tz('Asia/Kolkata')
        var hours = end_date.diff(start_date, 'seconds') / 60 / 60;
        data.actual_hrs = hours ? parseFloat(hours.toFixed(2)) : 0
        console.log(data, 'dhnefhnwi3finalal')
        await TimeSheetService.update(data);
        localStorage.removeItem('timer')
        this.$store.commit('SET_IN_PROGRESS', false)
        return issueData.issueId*/
        let $time = { "issue_id": issueData.issueId }
        await TimerService.processTime($time, issueData.type, false)
        localStorage.removeItem('timer')
        this.$store.commit('SET_IN_PROGRESS', false)
        return issueData.issueId


      }
      else {
        if (issueData && issueData.previousIssueId) {
          return issueData.previousIssueId
        }
        else {
          return null
        }
      }
    },
    menuShow() { },
    extraAvatar() {
      if (
        localStorage.getItem("selectedWorkSpaceId") ==
        localStorage.getItem("workSpaceId")
      ) {
        this.avatarName = localStorage.getItem("avatarName");
        this.show = true;
      } else {
        this.show = false;
      }
    },

    async getProjectData() {
      try {
        let workSpaceId = localStorage.getItem("workSpaceId");
        let res = await ProjectService.getWorkSpaceProject({
          work_space_id: workSpaceId,
        });
        this.projectData = res.length > 0 ? res : [];
        let projectId = localStorage.getItem("_PROJECT_ID_");
        if (!projectId && this.projectData.length > 0) {
          localStorage.setItem("_PROJECT_ID_", this.projectData[0].id);
          this.activeWorkSpace("pj", this.projectData[0].id);
        } else {
          this.activeWorkSpace("pj", projectId);
        }
      } catch (e) {
        this.projectData = [];
      }
    },
    async getAccountData() {
      try {
        let workSpaceId = localStorage.getItem("workSpaceId");
        let res = await AccountService.getAccountByIssue({
          work_space_id: workSpaceId,
        });
        this.accountData = res.length > 0 ? res : [];
        let accountId = localStorage.getItem("_ACCOUNT_ID_");
        if (!accountId && this.accountData.length > 0) {
          localStorage.setItem("_ACCOUNT_ID_", this.accountData[0].id);
          this.activeWorkSpace("ac", this.accountData[0].id);
        } else {
          this.activeWorkSpace("ac", accountId);
        }
      } catch (e) {
        this.accountData = [];
      }
    },

    async getAvatarMenu() {
      
      let res = await WorkSpaceService.getWorkSpaceByUser({active:1});
      if(res && res.length>0){

      for (let i = 0; i < res.length; i++) {
        if (i > 3) {
          if (res[i].id != localStorage.getItem("workSpaceId")) {
            this.addedMenu.push(res[i]);
          }
        }
      }
      // console.log("greater 4", this.addedMenu);
      return this.addedMenu;
      }
    },

    getAvatarLabel() {
      if (this.workSpaceData.length > this.maxAvatarLabelCount) {
        if (
          localStorage.getItem("selectedWorkSpaceId") ==
          localStorage.getItem("workSpaceId")
        ) {
          this.maxAvatarLabelCount = 5;
          let count = this.workSpaceData.length - this.maxAvatarLabelCount;
          return "+" + count;
        } else {
          let count = this.workSpaceData.length - this.maxAvatarLabelCount;
          return "+" + count;
        }
      } else {
        return "+" + "0";
      }
    },

    getRandomColor(index) {
      const availableColors = ["#C7D4E7", "#E7CED1", "#FDEFCA", "#F5D4DF"];
      if (index >= 0 && index < availableColors.length) {
        return availableColors[index];
      }
      return "#000000";
    },

    async getWorkSpaceData() {
      try {
        let res = await WorkSpaceService.getWorkSpaceByUser({active:1});
        this.workSpaceData = res;
        let selectId = localStorage.getItem("workSpaceId");
        this.workSpaceData.map((k) => (k.active = false));
        if (!selectId && this.workSpaceData.length > 0) {
          localStorage.setItem("workSpaceId", this.workSpaceData[0].id);
          this.$store.commit("WORK_SPACE_ID", this.workSpaceData[0].id);
          this.activeWorkSpace("ws", this.workSpaceData[0].id);
        } else {
          this.activeWorkSpace("ws", selectId);
          this.$store.commit("WORK_SPACE_ID", selectId);
        }
      } catch (e) {
        console.log(e);
      }
    },

    async activeWorkSpace(type, id) {
      if (type == "ws") {
        this.workSpaceData.map((k) => {
          if (k.id == parseInt(id)) {
            k.active = true;
          } else {
            k.active = false;
          }
        });
        this.workSpaceData = [...this.workSpaceData];
      }
      if (type == "pj") {
        this.projectData.map((k) => {
          this.$store.commit("PROJECT_ID", id);
          if (k.id == parseInt(id)) {
            k.active = true;
          } else {
            k.active = false;
          }
        });
        this.projectData = [...this.projectData];
      }
      if (type == "ac") {
        this.accountData.map((k) => {
          if (k.id == parseInt(id)) {
            this.$store.commit("ACCOUNT_ID", id);
            k.active = true;
          } else {
            k.active = false;
          }
        });
        this.accountData = [...this.accountData];
      }
    },

    selectProject(data) {
      this.show = false;
      localStorage.setItem("_PROJECT_ID_", data.id);
      this.activeWorkSpace("pj", data.id);
      // location.reload();
    },
    selectAccount(data) {
      this.show = false;
      localStorage.setItem("_ACCOUNT_ID_", data.id);
      this.activeWorkSpace("ac", data.id);
      // location.reload();
    },
    // for extra avatar
    selectWorkSpaceMenu(data) {
      this.show = true;
      localStorage.setItem("selectedWorkSpaceId", data.id);
      // let name = data.name ? data.name.slice(0,1) : '0'
      // this.avatarName = name
      localStorage.setItem("avatarName", data.name);
      localStorage.setItem("workSpaceId", data.id);
      this.activeWorkSpace(data.id);
      // location.reload();
    },
    getRendomColor() {
      return this.colors[Math.floor(Math.random() * this.colors.length)];
    },
    async startTimer($type,$taskSelected) {
      console.log($taskSelected)
      let timerStatus=localStorage.getItem('timer')
      let $timerStatus =JSON.parse(timerStatus)
      if($timerStatus){
        await this.closeTimer($timerStatus,$type)
        timerStatus = null
      }

      let userDetails = localStorage.getItem("user_details")
          userDetails = JSON.parse(userDetails)
           await UserService.update({
            id: userDetails.id,
            stage: `in ${$type}`,
          });

          
      console.log($timerStatus,'alert111111111')
      if(!timerStatus || $timerStatus.type!=$type){
        this.workTimingId=JSON.parse(localStorage.getItem("_WORK_TIMING_"))
          if(this.workTimingId && this.workTimingId.id){
            let issueId = await this.updateTimeSheet()
            this.taskType = $type
            await this.addDialog3($type, issueId,$taskSelected)

            // await TimerService.processTime($time,$type,true)

          }
      }
      else{
      this.timerDialog = true;
      }

    },

    async addDialog3(value, issueId,$taskSelected) {
        //  alert(issueId)
        // alert(this.workTimingId.id)
        this.$store.commit('SET_HIDE_TIMER', true)
        this.timerTitle = value == 'meeting' ? 'Meeting' : 'Break'
      this.timerDialog = true;
      // let userData = JSON.parse(localStorage.getItem("user_details"))
      let data = {}
    /*   data.company_id = userData.company_id
      data.customer_id = 0
      data.issue_id = 0
      data.project_id = 0
      data.resource_id = userData.id
      data.stage = 'pending'
      data.start_date = moment(new Date()).utcOffset("+05:30").format('YYYY/MM/DD HH:mm:ss')
      data.entry_date = moment(new Date()).utcOffset("+05:30").format('YYYY/MM/DD HH:mm:ss')
      let task = await TaskService.findAll({ task_type: value })
      data.task_id = task[0].id
     */  let $timer = {
        type: value,
        start: JSON.stringify(moment(new Date())),
        taskId: data.task_id,
      }
      if($timer.type.toLowerCase() =='meeting'){
        $timer.meetingName=this.meetingData && this.meetingData.name?this.meetingData.name:null        
        this.timerName = $timer.type && $timer.type.toLowerCase()=='meeting' ? $timer.meetingName:$timer.type
      }
      if (issueId) {
        $timer.previousIssueId = issueId
        await IssueService.update({id:issueId,stage:'pending'})

      }
      console.log($timer,'timeeeeee')
      data.description = value
      localStorage.setItem("timer", JSON.stringify($timer))
      this.$store.commit('SET_IN_PROGRESS', true)
      console.log(data,value,$taskSelected,"taskkkkkkkkkkkkkkkkkk")
      await TimerService.processTime(data, value, true,$taskSelected)          
      let status2 = this.$store.getters.getReloadTheIssue;
      this.$store.commit("RELOAD_THE_ISSUE", !status2);
      
      
    },

    async closeTimer($event,type) {
      // alert('closetimer')
      console.log($event,'tharikkkkk')
      this.timerDialog = false;
      this.meetingTimer = false
      this.$store.commit('SET_IN_PROGRESS', false)
      //await this.updateTimeSheet2($event);
      let $time = {}
      console.log($time,'alert222222')
      await TimerService.processTime($time, $event.type, false,null,null,$event && $event.issueId && $event.issueId>0?$event.issueId:$event.previousIssueId && $event.previousIssueId>0?$event.previousIssueId:null)
      this.$store.commit('SET_IN_PROGRESS', false)
      if ($event.previousIssueId && !type) {
        // alert('previousiddd')
        let userData = JSON.parse(localStorage.getItem("user_details"))
        let data = {}
        let res = await IssueService.findOne($event.previousIssueId);
        data.company_id = userData.company_id
        data.customer_id = res[0].account_id
        data.description = res[0].name
        data.issue_id = res[0].id
        data.project_id = res[0].project_id
        data.resource_id = userData.id
        data.stage = 'pending'
        data.task_id = res[0].task_id
        await TimerService.processTime(data, 'issue', true)
        let $start = moment(new Date())
        let $timer = {
          "type": 'issue',
          "issueId": $event.previousIssueId,
          "start": JSON.stringify($start)
        }
        localStorage.setItem("timer", JSON.stringify($timer))
        this.$store.commit('SET_IN_PROGRESS', true)         
        let status2 = this.$store.getters.getReloadTheIssue;
        this.$store.commit("RELOAD_THE_ISSUE", !status2);
      }else{
        console.log($event,type , 'kbjgjkjjkbbkb')
        await this.createTimeSheet($event,type)
      }
      this.$store.commit('SET_HIDE_TIMER', false)
    },
    minimizeTimer() {
      this.meetingTimer = true
      this.timerDialog = false;
      this.$store.commit('SET_IN_PROGRESS', true)
      this.$store.commit('SET_HIDE_TIMER', false)
    },
    openTimerDialog() {
      let timerData = JSON.parse(localStorage.getItem("timer"))
      if (timerData && timerData.type != 'issue') {
        this.timerDialog = true
        this.meetingTimer = false;
        this.timerTitle = timerData.type
        this.taskType = timerData.type
        this.$store.commit('SET_IN_PROGRESS', false)
      }
      else {
        this.timerDialog = false
      }
    },
    async updateTimeSheet2($timer) {
      let res = await TimeSheetService.findAll({ task_id: $timer.taskId, "stage": "pending" });
      res = lodash.orderBy(res, ['id'], ['desc']);
      let timesheetData = res[0]
      let data = {}
      data.id = timesheetData.id
      data.end_date = moment(new Date()).utcOffset("+05:30").format('YYYY/MM/DD HH:mm:ss')
      let end_date = moment(new Date()).tz('utc')
      data.stage = 'completed'
      let start_date = moment(timesheetData.start_date, true).tz('Asia/Kolkata')
      var hours = end_date.diff(start_date, 'seconds') / 60 ;
      data.actual_hrs = hours ? parseFloat(hours.toFixed(2)) : 0
      // console.log(data, 'dhnefhnwi3finalal')
      await TimeSheetService.update(data);
      this.$store.commit('SET_IN_PROGRESS', false)
    },
    async createTimeSheet(issueData,type) {
    console.log(issueData,'issueData checkingss')
      if (issueData && issueData.previousIssueId &&!type) {
        // alert(issueData.previousIssueId)
        let userData = JSON.parse(localStorage.getItem("user_details"))
        let data = {}
        let res = await IssueService.findOne(issueData.previousIssueId);
        data.company_id = userData.company_id
        data.customer_id = res[0].account_id
        data.description = res[0].name
        data.issue_id = res[0].id
        data.project_id = res[0].project_id
        data.resource_id = userData.id
        data.stage = 'pending'
        data.task_id = res[0].task_id
        data.start_date = moment(new Date()).utcOffset("+05:30").format('YYYY/MM/DD HH:mm:ss')
        data.entry_date = moment(new Date()).utcOffset("+05:30").format('YYYY/MM/DD HH:mm:ss')
        await TimerService.processTime(data, "issue", true);
        // await TimeSheetService.create(data);
        let $start = moment(new Date()).utcOffset("+05:30").format('YYYY/MM/DD HH:mm:ss')
        let $timer = {
          "type": 'issue',
          "issueId": issueData.previousIssueId,
          "start": JSON.stringify($start)
        }
        localStorage.setItem("timer", JSON.stringify($timer))
        this.$store.commit('SET_IN_PROGRESS', true)
      }
    },
    // closeDialog() {
    //   this.dialog = false;
    // },
    openSideBar() {
      this.$refs.NavigationDrawer.drawer = true;
    },
    openTeamStatus() {
      this.$refs.TeamStatus.drawer = !this.$refs.TeamStatus.drawer;
      this.$refs.Notification.drawer = false
    },
    closedMenu(){
      this.menu = false
    },
    openNotification() {
      this.workTimingId=JSON.parse(localStorage.getItem("_WORK_TIMING_"))
      if(this.workTimingId && this.workTimingId.id){
      this.$refs.Notification.drawer = !this.$refs.Notification.drawer;
      this.$refs.TeamStatus.drawer = false
      }
    },
  },
};
</script>

<style scoped>
.iconDiv {
  position: absolute;
  right: 500px;
}

.img {
  width: 25px;
  height: 25px;
}

>>>.v-menu>.v-overlay__content>.v-card,
.v-menu>.v-overlay__content>.v-sheet,
.v-menu>.v-overlay__content>.v-list {
  background: rgb(var(--v-theme-surface));
  border-radius: inherit;
  overflow: auto;
  height: 100%;
  width: 150%;
  box-shadow: 0px 5px 5px -3px var(--v-shadow-key-umbra-opacity, rgba(0, 0, 0, 0.2)),
    0px 8px 10px 1px var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.14)),
    0px 3px 14px 2px var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.12));
  width: 50% !important;
  margin-left: 15%;
  margin-top: 1%;
}

>>>.mdi-check::before {
  color: #03b603;
  content: "\F012C";
}

/* .avatar:hover {
  cursor: pointer;
  width: 3rem;
  height: 3rem;
} */

>>>.work-space-menu {
  cursor: pointer;
}

>>>.menus {
  cursor: pointer;
}

>>>.menus:hover {
  background-color: aliceblue;
}

.view-all {
  position: relative;
  color: blue;
  padding: 37%;
  cursor: pointer;
}

>>>.v-menu>.v-overlay__content>.v-card,
>>>.v-menu>.v-overlay__content>.v-sheet,
>>>.v-menu>.v-overlay__content>.v-list {
  background: rgb(var(--v-theme-surface));
  border-radius: inherit;
  overflow: auto;
  height: 100%;
  width: 150%;
  box-shadow: 0px 5px 5px -3px var(--v-shadow-key-umbra-opacity, rgba(0, 0, 0, 0.2)),
    0px 8px 10px 1px var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.14)),
    0px 3px 14px 2px var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.12));
}

>>>.v-list-item--one-line .v-list-item-subtitle {
  /* -webkit-line-clamp: 1; */
  font-size: 60%;
  font-weight: bold;
  background-color: rgb(170, 163, 163);
  /* outline: #4CAF50 solid 2px; */
  margin: 3px;
  width: 20%;
  border-radius: 3px;
  padding: 1%;
  text-align: start;
}

.subtitle {
  background-color: rgb(207, 202, 202);
  margin: 3px;
  /* color: ; */
  font-size: 11px;
  font-weight: 600;
  text-align: start;
  color: grey;
  border-radius: 5px;
  padding: 5px;
}

.btn-color {
  filter: invert(84%) sepia(0%) saturate(6234%) hue-rotate(246deg) brightness(87%) contrast(156%);
}

.plus-menu .v-list-item {
  width: 250px;
}

/* .v-list-item .v-list-item--link v-theme--myCustomLightTheme v-list-item--density-default v-list-item--one-line v-list-item--variant-text */
</style>
